<template>
    <div style="background: var(--v-component-base) !important">
        <v-card>
            <v-card-text>
                <v-tabs v-model="vesselManagementTab" height="30px">
                    <v-tab href="#rmsVesselVoyages" @click="activeTab != 'vesselManagement/rmsVesselVoyages' ? $router.push({ hash: '#vesselManagement/rmsVesselVoyages' }) : ''"> <v-icon class="mr-2" :color="setIconColor('rmsVesselVoyages')">sailing</v-icon> RMS Vessel Voyages </v-tab>
                    <v-tab href="#sailingSchedule" @click="activeTab != 'vesselManagement/sailingSchedule' ? $router.push({ hash: '#vesselManagement/sailingSchedule' }) : ''"> <v-icon class="mr-2" :color="setIconColor('sailingSchedule')">event</v-icon> Sailing Schedules </v-tab>
                    <v-tab href="#bookings" @click="activeTab != 'vesselManagement/bookings' ? $router.push({ hash: '#vesselManagement/bookings' }) : ''" > <v-icon class="mr-2" :color="setIconColor('bookings')">menu_book</v-icon> Bookings </v-tab>
                    <v-tab href="#shipmentFiles" @click="activeTab != 'vesselManagement/shipmentFiles' ? $router.push({ hash: '#vesselManagement/shipmentFiles' }) : ''"> <v-icon class="mr-2" :color="setIconColor('shipmentFiles')">source</v-icon> Shipment Files </v-tab>
                    <v-tab href="#shipments" @click="activeTab != 'vesselManagement/shipments' ? $router.push({ hash: '#vesselManagement/shipments' }) : ''"> <v-icon class="mr-2" :color="setIconColor('shipments')">local_shipping</v-icon> Shipments </v-tab>
                </v-tabs>
                <v-tabs-items v-model="vesselManagementTab" class="my-0 py-0"
                    style="background: var(--v-component-base) !important; ">
                    <v-tab-item value="rmsVesselVoyages">
                        <RMSVesselVoyages />
                    </v-tab-item>
                    <v-tab-item value="sailingSchedule">
                        <!-- <RMSVesselVoyages/> -->
                    </v-tab-item>
                    <v-tab-item value="bookings">
                        <Bookings />
                    </v-tab-item>
                    <v-tab-item value="shipmentFiles">
                        <!-- <ShipmentFiles /> -->
                    </v-tab-item>
                    <v-tab-item value="shipments">
                        <!-- <Shipments /> -->
                    </v-tab-item>
                </v-tabs-items>

            </v-card-text>
        </v-card>

    </div>
</template>
  
<script>
import RMSVesselVoyages from "../Admin/vesselManagement/RMSVesselVoyages.vue";
import Bookings from "../Admin/vesselManagement/Bookings.vue";
// import ShipmentFiles from "../Admin/vesselManagement/ShipmentFiles.vue";
// import Shipments from "../Admin/vesselManagement/Shipments.vue";

export default {
    components: {
        RMSVesselVoyages,
        Bookings,
        // ShipmentFiles,
        // Shipments,
    },
    data: () => ({
        vesselManagementTab: 'rmsVesselVoyages',
    }),
    mounted() {
        if (window.location.hash) {
            let component = decodeURI(window.location.hash).split('#')[1];
            this.activeTab = component
        } else {
            this.$router.push({
                hash: '#vesselManagement/rmsVesselVoyages'
            })
        }
    },
    created() {

    },
    computed: {

    },
    watch: {

    },
    methods: {
        setIconColor(tab){
            if(tab === this.vesselManagementTab){ return 'primary' } else { return 'grey' }
        }
    },
};
</script>
  
<style scoped>
.scroll {
    overflow-y: scroll;
}
</style>